import React, { ReactNode, useMemo, useState } from "react";
import { Text } from "@chakra-ui/react";

type Props = {
  organization: any;
};

const getSalesContactType = (org: any) => {
  const cp = org?.contactPointsCollection?.items.filter((cp: any) => {
    if (cp?.contactTypes?.join("").toLowerCase().indexOf("sales") >= 0) {
      return cp;
    }
    return false;
  });
  return cp && cp.length > 0 ? cp[0] : null;
};

const OrgContactInfo = (data: Props) => {
  const [org, setOrg] = useState(data?.organization);

  const contactPoint = useMemo(() => getSalesContactType(org), [org]);

  return (
    <>
      <Text as="span" fontWeight="Bold">
        {org?.name}
      </Text>
      <br />
      <Text as="span">
        {org?.streetAddress}
        <br />
        {org?.addressLocality}, {org?.addressRegion} {org?.postalCode}
      </Text>
      {org?.telephone ? (
        <Text as="span">
          <br /> <br />
          <Text as="span" fontWeight="bold">
            Phone:
          </Text>
          <br />
          {contactPoint?.telephone}
          <br />
          <Text as="span" fontWeight="bold">
            {contactPoint?.alphaNumericTelephoneEquivalent} (TTY{" "}
            {contactPoint?.ttNumber})
          </Text>
        </Text>
      ) : null}
      {org?.fax ? (
        <Text as="span">
          <br /> <br />
          <Text as="span" fontWeight="bold">
            Fax:
          </Text>
          <br />
          {org?.fax}
        </Text>
      ) : null}
    </>
  );
};

export default React.memo(OrgContactInfo);
